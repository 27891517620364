/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from "react";
import { useHistory } from "react-router-dom";
import Actor from "./Actor";
// import { useParams } from "react-router-dom";

const Photos = ({ Actors }) => {
  const history = useHistory();

  return (
    <section
      className="blog-post-thumb"
      style={{ backgroundImage: 'url("../img/bg/movie_details_bg.jpg")' }}
    >
      <div className="details-container">
        <div className="movie-item movie-item-two mt-0">
          <div className="row justify-content-center">
            <div className="section-title title-style-three text-center mb-70 mt-4">
              <h2 className="title">Acteurs</h2>
              <span className="title">Acteurs ayant joué dans ce film</span>
            </div>
          </div>

          <ul className="actors_desc col-xl-3 col-lg-4 col-sm-6">
            {Actors.slice(0, 4).map((actor) => {
              console.log({ actor });
              return (
                <Actor
                  actor={actor}
                  key={actor.id}
                  onClickActor={() => {
                    history.push(`/actorDetails/${actor?.id}`);
                  }}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Photos;
