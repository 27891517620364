import React from "react";

export default function Pagination({ totalPages, onSelect, currentPage }) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8"></div>
      </div>
      <div className="row movie-item-row">
        <div className="custom-col-"></div>
        <div className="pagination-wrap  mt-60">
          <nav>
            <ul className="">
              <li className={`${1 == currentPage ? "active hidden" : ""}`}>
                <a onClick={() => onSelect(Number(currentPage) - 1)} href="#">
                  Précédente
                </a>
              </li>
              ...
              {
                <li className={`${"active"}`}>
                  <a onClick={() => onSelect(currentPage)} href="#">
                    {currentPage}
                  </a>
                </li>
              }
              ...
              <li className={`${currentPage === 500 ? "active" : ""}`}>
                <a onClick={() => onSelect(Number(currentPage) + 1)} href="#">
                  Prochaine
                </a>
              </li>
            </ul>
            <br />
            <div>
              <p className="mb-0">Entrée un numéro de page</p>{" "}
              <input
                className="mt-0"
                onChange={(e) =>
                  e?.target.value <= 500 && e?.target.value > 1
                    ? onSelect(e?.target.value)
                    : null
                }
                type="number"
              />
              {totalPages >= 500 ? (
                <p> Totales de pages : {500} </p>
              ) : (
                <p> Totales de pages : {totalPages} </p>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
