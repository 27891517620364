import React from "react";
const BandeAccueil = () => {
  return (
    <div
    // className="services-area services-bg-two tete"
    // // style={{
    // //   backgroundImage: 'url("../../img/bg/montage-fond-equipe.png")',
    // // }}
    >
      <img className="tete" src="../../img/bg/montage-fond-equipe.png" />
      {/* <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 order-0 order-lg-2"></div>
          <div className="col-xl-5 col-lg-6">
            <div className="services-content-wrap">
              <div className="section-title mb-0"></div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default BandeAccueil;
