import React from "react";

const BanniereRecherche = () => {
  return (
    <section
      className="recherche-services-area services-bg-two"
      style={{ backgroundImage: 'url("../img/banner/banner_bg012.jpg")' }}
    ></section>
  );
};
export default BanniereRecherche;
