import React from "react";

const NotFound = () => {
    return (
  
 
        <div className="ami">
            <h1>Désolé !</h1>
            <img src="notfound.jpg" alt="page 404" />
        </div>


    )
}

export default NotFound;